import {
  DELETE_ADDRESS,
  DELETE_ADDRESS_SUCCESS,
  FETCH_ADDRS,
  FETCH_ADDRS_ERROR,
  FETCH_ADDRS_SUCCESS,
  LOGOUT_SUCCESS,
  CHECK_ADDRESS,
  CHECK_ADDRESS_FAILED,
  CHECK_ADDRESS_SUCCESS,
  CLEAR_RECEIVER,
  CLEAR_SENDER,
  SELECT_ADDR_TO_EDIT,
  CREATE_ADDR_UPDATE,
  LABEL_CREATE_SUCCESS,
  RECEIVER_ADDR_SELECT,
  RECEIVER_ADDR_UPDATE,
  SENDER_ADDR_SELECT,
  SENDER_ADDR_UPDATE,
  CLEAR_ADDR_FORM,
  ADD_GIFT_SENDER_ADDR,
  ADD_GIFT_RECEIVER_ADDR,
  EXCHANGE_ADDR_UPDATE,
  EXCHANGE_ADDR_SELECT,
  SET_DEFAULT_ADDRESS,
  SELECT_ORDER,
  SEARCH_ADDRESS_TEXT_ENTERED,
  SET_ADDRESS_PAGE_SIZE,
  SESSION_AUTH_LOGOUT,
  CHECKOUT_ADDR_UPDATE,
  CHECKOUT_ADDR_SELECT,
  CREATE_CHECKOUTS_SUCCESS,
  CLEAR_CHECKOUT_ADDRESS,
  SELECT_CHECKOUT_CONFIG,
  CLEAR_CHECKOUT_CONFIG,
} from "../../constants/types";

const INITIAL_ADDR_STATE = {
  loading: false,
  errors: "",
  addresses: [],
  addressResponse: {},
  defaultAddress: null,
  searchText: "",
  currentPage: 1,
  pageSize: 25,
};
export function addressListReducer(state = INITIAL_ADDR_STATE, action) {
  switch (action.type) {
    case FETCH_ADDRS:
      return { ...state, loading: true, errors: "" };
    case FETCH_ADDRS_SUCCESS:
      return {
        ...state,
        loading: false,
        errors: "",
        addresses: action.payload.list,
        addressResponse: action.payload.response,
        currentPage: action.payload.currentPage,
      };
    case FETCH_ADDRS_ERROR:
      return { ...state, loading: false, errors: action.payload.errors };
    case DELETE_ADDRESS:
      return { ...state, loading: true, errors: "" };
    case DELETE_ADDRESS_SUCCESS:
      //TODO: Refactor
      let temp = state.addresses;
      let defaultAddress = state.defaultAddress;
      temp.forEach((addr, index) => {
        if (addr.id === action.payload) {
          temp.splice(index, 1);
          if (
            defaultAddress &&
            defaultAddress.id &&
            action.payload === defaultAddress.id
          ) {
            defaultAddress = null;
          }
        }
      });
      return {
        ...state,
        loading: false,
        addresses: temp,
        defaultAddress: defaultAddress,
        errors: "",
      };
    case SET_DEFAULT_ADDRESS:
      return { ...state, defaultAddress: action.payload };
    case SEARCH_ADDRESS_TEXT_ENTERED:
      return { ...state, searchText: action.payload };
    case SET_ADDRESS_PAGE_SIZE:
      return { ...state, pageSize: action.payload };
    case LOGOUT_SUCCESS:
    case SESSION_AUTH_LOGOUT:
      return INITIAL_ADDR_STATE;
    default:
      return state;
  }
}

const INITIAL_CREATE_ADDR_STATE = {
  name: "",
  company: "",
  phone: "",
  email: "",
  street1: "",
  street2: "",
  city: "",
  state: "",
  zip_code: "",
  country: "US",
  object_id: null,
};

export function createAddrReducer(state = INITIAL_CREATE_ADDR_STATE, action) {
  switch (action.type) {
    case CREATE_ADDR_UPDATE:
      return { ...state, [action.payload.prop]: action.payload.value };
    case SELECT_ADDR_TO_EDIT:
      return { ...action.payload };
    case CLEAR_ADDR_FORM:
      return INITIAL_CREATE_ADDR_STATE;
    case LABEL_CREATE_SUCCESS:
      // clear the address when the label is created
      return INITIAL_CREATE_ADDR_STATE;
    case CHECK_ADDRESS_SUCCESS:
      return INITIAL_CREATE_ADDR_STATE;

    case DELETE_ADDRESS_SUCCESS:
      return INITIAL_CREATE_ADDR_STATE;
    case LOGOUT_SUCCESS:
    case SESSION_AUTH_LOGOUT:
      return INITIAL_CREATE_ADDR_STATE;
    default:
      return state;
  }
}

const INITIAL_ADDRESS_STATE = {
  loading: false,
  errors: null,
  warnings: "",
};

export function addressFormReducer(state = INITIAL_ADDRESS_STATE, action) {
  switch (action.type) {
    case CHECK_ADDRESS:
      return { ...state, loading: true, errors: null };
    case CHECK_ADDRESS_SUCCESS:
      return { ...state, loading: false, errors: null, warnings: "" };
    case CHECK_ADDRESS_FAILED:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
        warnings: action.payload.warnings,
      };
    case LOGOUT_SUCCESS:
    case SESSION_AUTH_LOGOUT:
    case CLEAR_ADDR_FORM:
      return INITIAL_ADDRESS_STATE;
    default:
      return state;
  }
}

const INITIAL_RECEIVER_STATE = {
  name: "",
  phone: "",
  company: "",
  email: "",
  street1: "",
  street2: "",
  city: "",
  state: "",
  zip_code: "",
  country: "US",
  object_id: null, //shippo object_id
};

export function receiverAddrReducer(state = INITIAL_RECEIVER_STATE, action) {
  switch (action.type) {
    case RECEIVER_ADDR_UPDATE:
      return { ...state, [action.payload.prop]: action.payload.value };
    case RECEIVER_ADDR_SELECT:
      return { ...action.payload };
    case SELECT_ORDER:
      if (action.payload?.recipient_address) {
        return { ...action.payload.recipient_address };
      }
      return INITIAL_RECEIVER_STATE;
    case ADD_GIFT_RECEIVER_ADDR:
      return { ...action.payload };
    // clear the address when the label is created
    case LABEL_CREATE_SUCCESS:
    case CLEAR_RECEIVER:
      // clear the selected receiver address
      return INITIAL_RECEIVER_STATE;
    case DELETE_ADDRESS_SUCCESS:
      let address = { ...state };
      if (!!address?.id && action.payload === address.id) {
        return INITIAL_RECEIVER_STATE;
      } else {
        return state;
      }
    case LOGOUT_SUCCESS:
    case SESSION_AUTH_LOGOUT:
      return INITIAL_RECEIVER_STATE;
    default:
      return state;
  }
}

const INITIAL_SENDER_STATE = {
  name: "",
  phone: "",
  company: "",
  email: "",
  street1: "",
  street2: "",
  city: "",
  state: "",
  zip_code: "",
  country: "US",
  object_id: null,
};

export function senderAddrReducer(state = INITIAL_SENDER_STATE, action) {
  switch (action.type) {
    case SENDER_ADDR_UPDATE:
      return { ...state, [action.payload.prop]: action.payload.value };
    case SENDER_ADDR_SELECT:
      return { ...action.payload };
    case ADD_GIFT_SENDER_ADDR:
      return { ...action.payload };
    // clear the address when the label is created
    case LABEL_CREATE_SUCCESS:
    case CLEAR_SENDER:
      return INITIAL_SENDER_STATE;
    case DELETE_ADDRESS_SUCCESS:
      let address = { ...state };
      if (!!address?.id && action.payload === address.id) {
        return INITIAL_RECEIVER_STATE;
      } else {
        return state;
      }
    case LOGOUT_SUCCESS:
    case SESSION_AUTH_LOGOUT:
      return INITIAL_SENDER_STATE;
    default:
      return state;
  }
}

const INITIAL_CHECKOUT_STATE = {
  name: "",
  phone: "",
  company: "",
  email: "",
  street1: "",
  street2: "",
  city: "",
  state: "",
  zip_code: "",
  country: "US",
  object_id: null, //shippo object_id
};

export function checkoutAddrReducer(state = INITIAL_RECEIVER_STATE, action) {
  switch (action.type) {
    case CHECKOUT_ADDR_UPDATE:
      return { ...state, [action.payload.prop]: action.payload.value };
    case CHECKOUT_ADDR_SELECT:
      return { ...action.payload };
    case CLEAR_CHECKOUT_CONFIG:
    case CLEAR_CHECKOUT_ADDRESS:
      return INITIAL_CHECKOUT_STATE;
    case DELETE_ADDRESS_SUCCESS:
      let address = { ...state };
      if (!!address?.id && action.payload === address.id) {
        return INITIAL_CHECKOUT_STATE;
      } else {
        return state;
      }
    case CREATE_CHECKOUTS_SUCCESS:
    case SELECT_CHECKOUT_CONFIG:
      if (action.payload?.default_address_from)
        return { ...action.payload.default_address_from };
      else return INITIAL_CHECKOUT_STATE;
    case LOGOUT_SUCCESS:
    case SESSION_AUTH_LOGOUT:
      return INITIAL_CHECKOUT_STATE;
    default:
      return state;
  }
}
