import React, { useEffect, useState } from "react";
import "../../../scss/orders.scss";
import { connect } from "react-redux";
import OrdersList from "./OrdersList";
import Dropdown from "react-bootstrap/Dropdown";
import {
  getConnectedStores,
  getOrders,
  getOrdersByPage,
  searchOrders,
  selectOrder,
  setOrdersPageSize,
  syncOrders,
} from "../../actions";
import { useCookies } from "react-cookie";
import { FiRefreshCw, FiUpload, FiX } from "react-icons/fi";
import Pagination from "../common/Pagination";
import history from "../../../History";
import * as ROUTES from "../../../constants/routes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import TRACKING_STATUS from "../../../assets/tracking-status.json";
import moment from "moment/moment";
import Popup from "../common/Popup";

const StatusFilters = {
  open: "Open",
  fulfilled: "Completed",
  awaiting_payment: "Payment Required",
  cancelled: "Cancelled",
};

const Orders = (props) => {
  const [cookies] = useCookies();
  const [storeFilter, setStoreFilter] = useState(null);
  const [statusFilter, setStatusFilter] = useState("");
  const [trackingFilter, setTrackingFilter] = useState("");
  const [dateFromFilter, setDateFromFilter] = useState("");
  const [dateToFilter, setDateToFilter] = useState("");
  const [showDate, setShowDate] = useState(false);
  const [dateElement, setDateElement] = useState(null);
  const [orderDesc, setOrderDesc] = useState(true);

  const filters = (() => {
    let filter = "";
    if (storeFilter) {
      if (!!filter) filter += "&";
      filter += `shop_id=${storeFilter.id}`;
    }
    if (statusFilter) {
      if (!!filter) filter += "&";
      filter += `state=${statusFilter}`;
    }
    if (trackingFilter) {
      if (!!filter) filter += "&";
      filter += `tracking_status=${trackingFilter}`;
    }
    if (dateFromFilter) {
      if (!!filter) filter += "&";
      filter += `date_from=${dateFromFilter}`;
    }
    if (dateToFilter) {
      if (!!filter) filter += "&";
      filter += `date_to=${dateToFilter}`;
    }
    if (!!filter) filter += "&";
    if (orderDesc) {
      filter += "ordering=~created_at";
    } else {
      filter += "ordering=created_at";
    }
    return filter;
  })();

  useEffect(() => {
    props.searchOrders("");
    props.getConnectedStores();
  }, []);

  useEffect(() => {
    setTimeout(
      props.getOrders(null, props.pageSize, props.searchText, filters),
      1000,
    );
  }, [props.searchText, props.pageSize, filters]);

  const renderStoreFilter = () => {
    const list = props.stores.map((store) => {
      return (
        <Dropdown.Item
          key={store.id}
          onClick={(e) => {
            setStoreFilter(store);
          }}
        >
          {store.shop_name || store.url}
        </Dropdown.Item>
      );
    });
    return (
      <Dropdown>
        <Dropdown.Toggle className={`${storeFilter ? "" : "placeholder"}`}>
          {storeFilter
            ? storeFilter.shop_name || storeFilter.url
            : "All Stores"}
        </Dropdown.Toggle>
        <Dropdown.Menu>{list}</Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderStatusFilter = () => {
    const list = Object.keys(StatusFilters).map((state) => {
      return (
        <Dropdown.Item
          key={state}
          onClick={(e) => {
            setStatusFilter(state);
          }}
        >
          {StatusFilters[state]}
        </Dropdown.Item>
      );
    });
    return (
      <Dropdown>
        <Dropdown.Toggle className={`${statusFilter ? "" : "placeholder"}`}>
          {StatusFilters[statusFilter] || "Order Status"}
        </Dropdown.Toggle>
        <Dropdown.Menu>{list}</Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderTrackingFilter = () => {
    const list = Object.keys(TRACKING_STATUS).map((status) => {
      return (
        <Dropdown.Item
          key={status}
          onClick={(e) => {
            setTrackingFilter(status);
          }}
        >
          {TRACKING_STATUS[status]}
        </Dropdown.Item>
      );
    });
    return (
      <Dropdown>
        <Dropdown.Toggle className={`${trackingFilter ? "" : "placeholder"}`}>
          {TRACKING_STATUS[trackingFilter] || "Tracking Status"}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item
            key={"unfulfilled"}
            onClick={(e) => {
              setTrackingFilter("unfulfilled");
            }}
          >
            Unfulfilled
          </Dropdown.Item>
          {list}
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const getDateFilter = (() => {
    let date = "";
    if (dateFromFilter) {
      date += moment(new Date(dateFromFilter)).format("MMM DD");
      date += " - ";

      if (dateToFilter) date += moment(new Date(dateToFilter)).format("MMM DD");
      else date += moment(new Date()).format("MMM DD");
    }
    return date;
  })();

  const renderDateFilter = () => {
    return (
      <div className={"order-date-filter"}>
        <div className={"dropdown"}>
          <button
            className={`btn btn-primary dropdown-toggle ${!getDateFilter ? "placeholder" : ""}`}
            onClick={(e) => {
              setDateElement(e.target);
              setShowDate(!showDate);
            }}
          >
            {getDateFilter || "Date Range"}
          </button>
        </div>

        <Popup
          element={dateElement}
          show={showDate}
          close={() => {
            setShowDate(false);
            setDateElement(null);
          }}
          className={`date-filter-selection flex-row align-items-center`}
        >
          <input
            type={"date"}
            value={dateFromFilter}
            onChange={(e) => {
              setDateFromFilter(e.target.value);
            }}
            max={moment(dateToFilter ? dateToFilter : new Date()).format(
              "YYYY-MM-DD",
            )}
          />
          <input
            type={"date"}
            value={dateToFilter}
            onChange={(e) => {
              if (
                !dateFromFilter ||
                moment(e.target.value).isBefore(dateFromFilter)
              ) {
                setDateFromFilter(e.target.value);
              }
              setDateToFilter(e.target.value);
            }}
            max={moment(new Date()).format("YYYY-MM-DD")}
          />
        </Popup>
      </div>
    );
  };

  return (
    <div className={`orders ${cookies.is_test ? "test-mode" : "live-mode"}`}>
      <div className={"settings-card"}>
        <div className="card-section head mb-2 d-flexflex-row justify-content-between align-items-center">
          <div
            className={
              "orders-filter-section d-flex flex-wrap flex-row align-items-center"
            }
          >
            {renderStoreFilter()}
            {renderStatusFilter()}
            {renderTrackingFilter()}
            {renderDateFilter()}
            {!!storeFilter ||
            !!statusFilter ||
            !!trackingFilter ||
            !!dateFromFilter ||
            !!dateToFilter ? (
              <button
                className={"button-secondary"}
                onClick={() => {
                  setStoreFilter(null);
                  setStatusFilter("");
                  setTrackingFilter("");
                  setDateToFilter("");
                  setDateFromFilter("");
                }}
              >
                <FiX />
              </button>
            ) : null}
          </div>
          <div className="d-flex flex-row flex-wrap align-items-center justify-content-end">
            <OverlayTrigger
              key={"upload-csv-tooltip"}
              placement={"auto"}
              overlay={
                <Tooltip
                  id={"upload-csv-tooltip"}
                  className={"upload-csv-tooltip"}
                >
                  {"Upload CSV"}
                </Tooltip>
              }
              delayShow={300}
              delayHide={150}
            >
              <button onClick={() => history.push(ROUTES.CSV_IMPORT_PAGE)}>
                <FiUpload />
              </button>
            </OverlayTrigger>
            <OverlayTrigger
              key={"sync-orders-tooltip"}
              placement={"auto"}
              overlay={
                <Tooltip
                  id={"sync-orders-tooltip"}
                  className={"sync-orders-tooltip"}
                >
                  {"Sync orders"}
                </Tooltip>
              }
              delayShow={300}
              delayHide={150}
            >
              <button
                onClick={() => {
                  props.searchOrders("");
                  props.syncOrders(() => {
                    // * Have it re-get orders after a certain delay (3 seconds should usually be enough)
                    setTimeout(() => {
                      props.getOrders(
                        null,
                        props.pageSize,
                        props.searchText,
                        filters,
                      );
                    }, 3000);
                  });
                }}
              >
                <FiRefreshCw />
              </button>
            </OverlayTrigger>
          </div>
        </div>
        <div className="card-section last list">
          <OrdersList
            list={props.orders}
            searchText={props.searchText}
            pageSize={props.pageSize}
            orderDesc={orderDesc}
            toggleOrder={() => setOrderDesc(!orderDesc)}
            goToLabels={(search) => {
              history.push(ROUTES.HOME + ROUTES.LABEL + `?search=${search}`);
            }}
            pagination={
              <Pagination
                current={props.current}
                count={props.count}
                pageSize={props.pageSize}
                getListByPage={(pageNo) =>
                  props.getOrdersByPage(
                    null,
                    pageNo,
                    props.pageSize,
                    props.searchText,
                    filters,
                  )
                }
                setPageSize={(size) => props.setOrdersPageSize(size)}
              />
            }
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ stores }) => ({
  orders: stores.orders,
  selected: stores.selectedOrder,
  loading: stores.loading,
  errors: stores.errors,
  searchText: stores.searchText,
  count: stores.ordersResponse?.count || null,
  current: stores.currentPage,
  pageSize: stores.pageSize,
  stores: stores.stores,
});

export default connect(mapStateToProps, {
  getOrders,
  selectOrder,
  searchOrders,
  syncOrders,
  setOrdersPageSize,
  getOrdersByPage,
  getConnectedStores,
})(Orders);
