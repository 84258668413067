import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link, matchPath, useLocation } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { ReactComponent as BoxLogo } from "../../assets/box-in-logo.svg";
import { ReactComponent as OverviewIcon } from "../../assets/overview-icon.svg";
import { ReactComponent as LabelsIcon } from "../../assets/labels-icon.svg";
import { ReactComponent as OrdersIcon } from "../../assets/orders-icon.svg";
import { ReactComponent as HelpIcon } from "../../assets/helpcenter-icon.svg";
import { ReactComponent as SettingIcon } from "../../assets/settings-icon.svg";
import { ReactComponent as StoreIcon } from "../../assets/connect-store-icon.svg";
import { ReactComponent as TestGear } from "../../assets/test-mode-icon.svg";
import { useCookies } from "react-cookie";
import { FiAlertCircle, FiInfo, FiShoppingCart } from "react-icons/fi";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FIREBASE_AUTH, SESSION_AUTH } from "../../constants/types";
import { missingDetails } from "./common/helpers";
import history from "../../History";
import { clearSelectedCheckout } from "../actions/CheckoutsActions";

export const NavItem = (props) => {
  const location = useLocation();
  let isCurrent = matchPath(location.pathname, {
    path: props.link,
    exact: false,
  });
  if (props.link === ROUTES.HOME + ROUTES.ACCOUNT) {
    isCurrent = matchPath(location.pathname, {
      path: props.link,
      exact: false,
    });
  }

  return (
    <li>
      <Link
        to={props.link}
        className={`nav-item col-12 d-flex flex-row align-items-center ${isCurrent ? "active" : ""}`}
      >
        <div className="d-flex flex-row align-items-center">
          {props.icon}
          <p className="mb-0">
            {props.name}
            {props.missing && !isCurrent && (
              <OverlayTrigger
                key={"business-details-missing"}
                placement="right"
                overlay={
                  <Tooltip
                    id="business-details-missing-tooltip"
                    className={"business-details-missing-tooltip"}
                  >
                    You are seeing this because you have missing information for
                    your business details
                  </Tooltip>
                }
                delayShow={300}
                delayHide={150}
              >
                <span>
                  <FiInfo className={"warning"} />
                </span>
              </OverlayTrigger>
            )}
          </p>
        </div>
        <div
          className={`counter mx-3  align-items-center justify-content-center ml-auto ${props.count && props.count > 0 ? "d-flex" : "d-none"}`}
        >
          {props.count}
        </div>
      </Link>
    </li>
  );
};

const Sidebar = (props) => {
  return (
    <div
      className={`sidebar d-none d-xl-flex flex-column col-2 p-0 align-items-center `}
    >
      <Link
        className="header d-flex flex-row align-items-center"
        to={ROUTES.LANDING}
      >
        <div>
          <BoxLogo />
        </div>
        <h2 className="mb-0">ShipShap</h2>
      </Link>
      <div className="nav-list d-flex flex-column col-12 p-0">
        <NavItem
          name={"Overview"}
          icon={<OverviewIcon />}
          link={ROUTES.HOME + ROUTES.DASHBOARD}
        />
        <NavItem
          name={"Orders"}
          icon={<OrdersIcon />}
          link={ROUTES.HOME + ROUTES.ORDERS}
        />
        <NavItem
          name={"Labels"}
          icon={<LabelsIcon />}
          link={ROUTES.HOME + ROUTES.LABEL}
        />
        <NavItem
          name={"Stores"}
          icon={<FiShoppingCart />}
          link={ROUTES.HOME + ROUTES.STORES}
        />
      </div>
      <div className="bottom d-flex flex-column col-12 p-0">
        <div className="">
          {/*<li>*/}
          {/*    <div className={`toggler nav-item col-12 d-flex flex-row align-items-center `} onClick={()=>switchCookies(cookies.is_test)}>*/}
          {/*        <div className="d-flex flex-row align-items-center">*/}
          {/*            <TestGear/>*/}
          {/*            <p className="mb-0">Testing Mode</p>*/}
          {/*        </div>*/}
          {/*        <div className={`gutter align-items-center justify-content-center ml-auto `}>*/}
          {/*            <div className="slider"></div>*/}
          {/*        </div>*/}
          {/*    </div>*/}
          {/*</li>*/}

          <NavItem
            name={"Help Center"}
            icon={<HelpIcon />}
            link={ROUTES.HELP_CENTER}
          />
          <NavItem
            name={"Settings"}
            icon={<SettingIcon />}
            link={ROUTES.HOME + ROUTES.ACCOUNT}
            missing={missingDetails(props.details)}
          />
        </div>
        {props.context.auth === FIREBASE_AUTH && (
          <div
            className="store d-flex flex-column align-items-center justify-content-center"
            onClick={() => {
              props.clearSelectedCheckout(() => {
                history.push(ROUTES.HOME + ROUTES.STORES + "?connect=true");
              });
            }}
          >
            <StoreIcon />
            <a>
              <button>Connect a Store</button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ businessDetails, applicationContext }) => ({
  details: businessDetails.details,
  context: applicationContext.context,
});

export default connect(mapStateToProps, { clearSelectedCheckout })(Sidebar);
