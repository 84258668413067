import { Link } from "react-router-dom";
import * as ROUTES from "../../../constants/routes";
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";

const Pagination = (props) => {
  const getShownPages = (currentPage, totalItems, pageSize) => {
    const noPages = Math.ceil(totalItems / pageSize);
    let pageNumbers = [1];
    if (currentPage > 2) {
      pageNumbers.push(currentPage - 1);
    }
    if (currentPage !== 1 && currentPage !== noPages) {
      pageNumbers.push(currentPage);
    }
    if (currentPage < noPages - 1) {
      pageNumbers.push(currentPage + 1);
    }
    if (noPages > 1) pageNumbers.push(noPages);

    while (
      pageNumbers.length < 5 &&
      pageNumbers[pageNumbers.length - 1] < noPages
    ) {
      pageNumbers.push(pageNumbers[pageNumbers.length - 1] + 1);
    }
    while (pageNumbers.length < 5 && pageNumbers[0] > 1) {
      pageNumbers.unshift(pageNumbers[0] - 1);
    }

    return pageNumbers;
  };

  const renderPageSize = () => {
    const pageSizes = [5, 10, 25, 50, 100];

    const list = pageSizes.map((item, idx) => {
      return (
        <Dropdown.Item key={item} onClick={() => props.setPageSize(item)}>
          {item}
        </Dropdown.Item>
      );
    });

    return (
      <Dropdown>
        <Dropdown.Toggle>{props.pageSize}</Dropdown.Toggle>
        <Dropdown.Menu>{list}</Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderPagination = () => {
    const shownPage = getShownPages(props.current, props.count, props.pageSize);

    const pages = shownPage.map((item, idx) => {
      if (item + 1 !== shownPage[idx + 1] && idx !== shownPage.length - 1) {
        return [
          item === props.current ? (
            <a className={"active"} key={`${item}-active`}>
              {item}
            </a>
          ) : (
            <a
              className={""}
              key={`${item}-inactive`}
              onClick={() => props.getListByPage(item)}
            >
              {item}
            </a>
          ),
          <span key={`${item}-span`} className={"ellipsis"}>
            ...
          </span>,
        ];
      } else {
        return item === props.current ? (
          <a className={"active"} key={`${item}-active`}>
            {item}
          </a>
        ) : (
          <a
            className={""}
            key={`${item}-inactive`}
            onClick={() => props.getListByPage(item)}
          >
            {item}
          </a>
        );
      }
    });

    if (props.current - 1 >= 1) {
      pages.unshift(
        <a
          className={"previous-action"}
          key={"prev"}
          onClick={() => props.getListByPage(props.current - 1)}
        >
          <FiChevronLeft size={20} />
        </a>,
      );
    }
    if (props.current + 1 <= Math.ceil(props.count / props.pageSize)) {
      pages.push(
        <a
          className={"next-action"}
          key={"next"}
          onClick={() => props.getListByPage(props.current + 1)}
        >
          <FiChevronRight size={20} />
        </a>,
      );
    }

    return <div className={"page-list"}>{pages}</div>;
  };

  return (
    <div className={"pagination-action"}>
      {renderPagination()}
      <div className={"page-size"}>{renderPageSize()}</div>
    </div>
  );
};

export default Pagination;
