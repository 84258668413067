import React, { Component } from "react";
import { connect } from "react-redux";
import { selectRate } from "../../actions";
import { Card } from "react-bootstrap";
import LOGO from "../../../assets/box-in-logo-green.svg";
import LOGO_WHITE from "../../../assets/box-in-logo.svg";
import MessageBlock from "../common/MessageBlock";
import { FiAlertCircle, FiInfo } from "react-icons/fi";
import { parseError } from "../../actions/ActionHelpers";

class RateSelection extends Component {
  renderDetails = (features) => {
    return <p>{features}</p>;
  };

  sortRates = () => {
    const rates = [...this.props.rates];
    return (
      Array.isArray(rates) &&
      rates.sort((rate1, rate2) => {
        return parseFloat(rate1?.amount) - parseFloat(rate2?.amount);
      })
    );
  };

  renderSingle = (selected) => {
    const sortedRates = this.sortRates();
    let min = sortedRates?.length > 0 ? this.sortRates()[0] : null;
    if (!min) return <></>;

    return (
      <div
        className={`rate-item col-12 ${selected === min.id ? "active" : ""}`}
        onClick={() => this.props.selectRate({ rate: min })}
        onKeyDown={() => this.props.selectRate({ rate: min })}
        role={"button"}
      >
        <div className="col-12 p-0 d-flex flex-row">
          <img
            src={LOGO}
            className="rate-icon inactive col-2 p-0 "
            alt={"shipshap_rate"}
          />
          <img
            src={LOGO_WHITE}
            className={"rate-icon active col-2 p-0 "}
            alt={"shipshap_rate"}
          />
          <div className="d-flex flex-column">
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-column">
                <h4>ShipShap</h4>
                <h5>{`${this.props.sender?.country === this.props.receiver?.country ? "Local" : "International"} Rate`}</h5>
              </div>
            </div>
            {min.service.terms && (
              <div className="rate-features col-12 p-0 d-flex flex-column">
                {this.renderDetails(min.service.terms)}
              </div>
            )}
          </div>
          <div className="d-flex flex-row ml-auto text-end">
            <p className="price">{`${parseFloat(min.amount).toFixed(2)} ${min.currency}`}</p>
          </div>
        </div>
      </div>
    );
  };

  renderAll = (selected) => {
    const sortedRates = this.sortRates();
    if (!sortedRates || sortedRates?.length == 0) return <></>;
    const list = sortedRates
      .filter((item) => {
        return (
          !this.props.paymentOnDeliveryAvailable ||
          item.service?.provider?.toString().toLowerCase() === "shipshap"
        );
      })
      .map((item) => {
        return (
          <div
            className={`rate-item col-12 ${selected === item.id ? "active" : ""}`}
            onClick={() => this.props.selectRate({ rate: item })}
            onKeyDown={() => this.props.selectRate({ rate: item })}
            role={"button"}
            key={item.id}
          >
            <div className="col-12 p-0 d-flex flex-row">
              <img
                src={item.service.provider_image}
                className="rate-icon col-2 p-0 "
                alt={item.service.provider}
              />
              <div className="d-flex flex-column">
                <div className="d-flex flex-row justify-content-between">
                  <div className="d-flex flex-column">
                    <h4>{item.service.provider}</h4>
                    <h5>{item.service.name}</h5>
                  </div>
                </div>
                {item.service.terms && (
                  <div className="rate-features col-12 p-0 d-flex flex-column">
                    {this.renderDetails(item.service.terms)}
                  </div>
                )}
              </div>
              <div className="d-flex flex-row ml-auto text-end">
                <p className="price">{`${parseFloat(item.amount).toFixed(2)} ${item.currency}`}</p>
              </div>
            </div>
          </div>
        );
      });
    return list;
  };

  renderList = () => {
    const id = this.props.selectedRate && this.props.selectedRate.id;
    if (this.props.rates.length === 0) {
      return (
        <div className="empty-list justify-content-center align-items-center text-center">
          <p className={"m-lg-auto text-lg-center"}>
            Request rates with the shipment details to see rates
          </p>
        </div>
      );
    }
    return (
      <div className="list-container ">
        <div className={""}>
          {this.props.showCarriers ? this.renderAll(id) : this.renderSingle(id)}
        </div>
      </div>
    );
  };

  render() {
    return (
      <div className="rates-selection settings-card ">
        <div className={"rates-container "}>
          <div className="rates-title card-section head ">
            <div className="text-title d-flex flex-column justify-content-start align-items-start">
              <h3 className="text-title">Rates</h3>
            </div>
          </div>
          <div className=" rates-list card-section last d-flex flex-column align-items-center">
            {this.props.rates.length > 0 && (
              <p className="info mr-auto text-">
                Select Your prefered shipping service & rate
              </p>
            )}
            {!this.props.loading && this.props.warnings && (
              <MessageBlock
                type={"warning"}
                message={parseError(this.props.warnings)}
                icon={<FiInfo />}
              />
            )}
            {this.props.loading ? (
              <div className="spinner-border text-dark"></div>
            ) : this.props.errors ? (
              <MessageBlock
                type={"error"}
                message={parseError(this.props.errors)}
                icon={<FiAlertCircle />}
              />
            ) : (
              <div className={"col-12 p-0"}>{this.renderList()}</div>
            )}

            {this.props.rates.length === 0 ? (
              <div></div>
            ) : (
              <div className=" align-items-center justify-content-center">
                <button
                  className="purchase-button"
                  disabled={
                    !(this.props.selectedRate && this.props.selectedRate.id)
                  }
                  onClick={() => this.props.togglePurchase()}
                >
                  Purchase Label
                </button>
              </div>
            )}
          </div>
          <br />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  rateChoices,
  senderAddress,
  receiverAddress,
  userDetails,
}) => ({
  rates: rateChoices.rates,
  selectedRate: rateChoices.selectedRate,
  loading: rateChoices.loading,
  errors: rateChoices.errors,
  warnings: rateChoices.warnings,
  sender: senderAddress,
  receiver: receiverAddress,
  showInternational: userDetails.info?.show_international_carriers,
});

export default connect(mapStateToProps, { selectRate })(RateSelection);
