import CONVERSION_FACTORS from "../../../assets/weight-conversion-factors.json";
import {
  CUSTOM_PACKAGE_I,
  FLAT_RATE_PACKAGE_I,
} from "../../../constants/types";

export const convertToWeightUnit = (value, fromUnit, toUnit) => {
  return (
    parseFloat(value) *
    CONVERSION_FACTORS[fromUnit.toString().toLowerCase().trim()][
      toUnit.toString().toLowerCase().trim()
    ]
  );
};

export const getTotalWeightInUnit = (parcels, toUnit) => {
  let totalWeight = 0;
  if (parcels?.parcels?.length > 0) {
    parcels.parcels.forEach((item) => {
      const quantity = parseInt(item.quantity) || 1;
      totalWeight += convertToWeightUnit(
        parseFloat(item.weight) * quantity,
        item.weight_unit,
        toUnit,
      );
    });
  } else if (parcels.selectedWeight?.weight) {
    const quantity = parseInt(parcels.selectedParcel?.quantity) || 1;
    totalWeight = convertToWeightUnit(
      parseFloat(parcels.selectedWeight.weight) * quantity,
      parcels.selectedWeight.weight_unit,
      toUnit,
    );
  }

  return totalWeight;
};

export const missingDetails = (details) => {
  let missing = false;
  if (!details || Object.keys(details).length === 0) {
    return true;
  }
  Object.keys(details).forEach((field, idx) => {
    let item = details[field];
    if ((field !== "is_verified" && !item) || item.toString().trim() === "") {
      missing = missing || true;
    }
  });
  return missing;
};

export const validatePackage = (
  selectedParcel,
  selectedWeight,
  packageType,
) => {
  let valid = true;
  const { length, width, height } = selectedParcel;
  const errors = {};
  let text = "";
  if (packageType === CUSTOM_PACKAGE_I) {
    if (!length || length <= 0) errors.length = true;
    if (!width || width <= 0) errors.width = true;
    if (!height || height <= 0) errors.height = true;
    if (errors.length || errors.width || errors.height) {
      text += "Dimensions";
      valid = false;
    }
  } else if (packageType === FLAT_RATE_PACKAGE_I && !selectedParcel.template) {
    errors.template = true;
    text += "Dimensions";
    valid = false;
  }
  if (!selectedWeight.weight || selectedWeight.weight <= 0) {
    errors.weight = true;
    text += text ? " and Weight" : "Weight";
    valid = false;
  }

  return { valid, errors, message: `Please enter a valid ${text} value` };
};

export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      reject(error);
    };
    reader.readAsDataURL(file);
  });
};

export const isDomesticKenyanShipment = (address_from, address_to) => {
  if (!address_from?.country || !address_to?.country) return false;
  return address_from.country === address_to.country;
};

export const toMoneyFormat = (value, currency) => {
  let options = currency
    ? { style: "currency", currency: currency }
    : { maximumSignificantDigits: 3 };
  let formatter = new Intl.NumberFormat("en-US", options);
  return formatter.format(value);
};

export const getQuantity = (item) => {
  if (!item) return 1;
  const feature = item.variation?.features?.find(
    (feature) => feature.feature_type == "quantity",
  );
  if (feature) {
    return parseInt(feature.value) * parseInt(item.quantity);
  }
  return item.quantity;
};
