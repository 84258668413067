import React, { useEffect, useRef } from "react";

const Popup = ({ element, show, close, children, className, top, right }) => {
  const popupContainer = useRef();
  useEffect(() => {
    if (element) {
      const rect = element?.getBoundingClientRect();
      popupContainer.current.style.top =
        top || (rect.height + 15).toString() + "px";
      popupContainer.current.style.right = right || "0px";
    }
  }, [element]);

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (popupContainer.current) {
        const elementRect = popupContainer.current.getBoundingClientRect();
        if (
          (e.x > elementRect.right + 25 ||
            e.x < elementRect.left - 25 ||
            e.y > elementRect.bottom ||
            e.y < elementRect.top - 100) &&
          show
        ) {
          close();
        }
      }
    });

    return () => {
      window.removeEventListener("click", (e) => {
        if (popupContainer.current) {
          const elementRect = popupContainer.current.getBoundingClientRect();

          if (
            (e.x > elementRect.right ||
              e.x < elementRect.left ||
              e.y > elementRect.bottom ||
              e.y < elementRect.top) &&
            show
          ) {
            close();
          }
        }
      });
    };
  }, [show]);

  return (
    <div
      className={`popup-modal ${show ? "active" : ""} ${className}`}
      ref={popupContainer}
    >
      {children}
    </div>
  );
};

const Item = ({ className, children, onClick }) => (
  <div
    className={`popup-item d-flex flex-row ${className}`}
    onClick={(e) => {
      if (onClick) {
        onClick(e);
      }
    }}
  >
    {children}
  </div>
);
Popup.Item = Item;

export default Popup;
