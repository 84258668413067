import AuthReducer from "./AuthReducer";
import LabelReducer from "./LabelReducer";
import { combineReducers } from "redux";
import {
  addressFormReducer,
  addressListReducer,
  checkoutAddrReducer,
  createAddrReducer,
  receiverAddrReducer,
  senderAddrReducer,
} from "./AddressReducer";
import { parcelsReducer } from "./ParcelReducer";
import { ratesReducer } from "./RatesReducer";
import { customsReducer } from "./CustomsReducer";
import { accountFormReducer, userReducer } from "./UserReducer";
import { purchaseLabelReducer } from "./PurchaseReducer";
import { newTemplateReducer, templateReducer } from "./TemplateReducer";
import { APIReducer } from "./IntegrationReducer";
import {
  contactFormReducer,
  faqReducer,
  mailingListReducer,
} from "./CommunicationReducer";
import {
  businessDetailsFormReducer,
  businessDetailsReducer,
} from "./BusinessDetailsReducer";
import { storeReducer } from "./StoreReducers";
import { applicationContextReducer } from "./ApplicationContextReducer";
import { checkoutsReducer } from "./CheckoutsReducer";
import { csvImportReducer } from "./CSVImportReducer";

export default combineReducers({
  auth: AuthReducer,
  labels: LabelReducer,
  addressList: addressListReducer,
  phonebookAddress: createAddrReducer,
  addressForm: addressFormReducer,
  senderAddress: senderAddrReducer,
  receiverAddress: receiverAddrReducer,
  checkoutAddress: checkoutAddrReducer,
  parcels: parcelsReducer,
  rateChoices: ratesReducer,
  customs: customsReducer,
  userDetails: userReducer,
  purchaseLabel: purchaseLabelReducer,
  api: APIReducer,
  mailingList: mailingListReducer,
  contactForm: contactFormReducer,
  accountForm: accountFormReducer,
  businessDetails: businessDetailsReducer,
  businessDetailsForm: businessDetailsFormReducer,
  applicationContext: applicationContextReducer,
  labelTemplate: templateReducer,
  newTemplate: newTemplateReducer,
  stores: storeReducer,
  faqs: faqReducer,
  checkouts: checkoutsReducer,
  csvImport: csvImportReducer,
});
