import React, { Component } from "react";
import { ReactComponent as UserIcon } from "../../../assets/user-setting-icon.svg";
import { ReactComponent as StoreIcon } from "../../../assets/store-setting-icon.svg";
import { ReactComponent as WebsiteIcon } from "../../../assets/business-website.svg";
import { ReactComponent as PinIcon } from "../../../assets/location-setting-icon.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/phone-setting-icon.svg";
import { ReactComponent as EmailIcon } from "../../../assets/email-setting-icon.svg";
import { connect } from "react-redux";
import { getAccountInfo } from "../../actions";
import * as ROUTES from "../../../constants/routes";
import { Link } from "react-router-dom";
import {
  downloadBusinessDocument,
  getBusinessDetails,
} from "../../actions/BusinessActions";
import {
  FiFile,
  FiHash,
  FiInfo,
  FiKey,
  FiDownload,
  FiMail,
  FiPhone,
  FiUser,
} from "react-icons/fi";
import { BiBriefcase } from "react-icons/bi";
import history from "../../../History";
import { setApplicationContext } from "../../actions/ApplicationContextActions";
import COUNTRYLIST from "../../../assets/country-list.json";
import { FIREBASE_AUTH } from "../../../constants/types";
import { Spinner } from "react-bootstrap";

class AccountDetails extends Component {
  componentDidMount = () => {};

  render() {
    const { pathname } = window.location;

    if (
      !this.props.info ||
      !this.props.info.full_name ||
      !this.props.info.phone_number ||
      !this.props.info.business_name ||
      !this.props.info.business_website ||
      !this.props.info.monthly_avg_shipment
    ) {
      return (
        <div className="account-details col-12 p-0 d-flex flex-column">
          <div className="settings-card">
            <div className="card-section head d-flex flex-row justify-content-between">
              <h3 className={"d-flex flex-row align-items-center"}>
                <Link
                  to={ROUTES.HOME + ROUTES.ACCOUNT}
                  className="d-flex d-lg-none mx-3 h2"
                >
                  {"<"}
                </Link>
                Basic Information
              </h3>
              <a
                onClick={() => {
                  this.props.setApplicationContext(
                    { returnURL: pathname, actionType: "edit" },
                    () => {
                      history.push(ROUTES.UPDATE_ACCOUNT_DETAILS);
                    },
                  );
                }}
                className="action"
              >
                Edit
              </a>
            </div>
            <div
              className={
                "card-section last d-flex flex-column flex-lg-row justify-content-between align-items-center"
              }
            >
              <div className={`col-12 col-lg-6 p-0 d-flex flex-column`}>
                <div className="d-flex flex-row align-items-center mb-3">
                  <div className="col-2">
                    <UserIcon size={25} />
                  </div>
                  <h4 className="h-1 mb-0">User Information</h4>
                </div>
                <div className="d-flex flex-row col-12 p-0">
                  <div className="col-2"></div>
                  <div className="d-flex flex-row col-12 p-0 ">
                    <div className="d-flex flex-column col-12 p-0">
                      <p className="info">Please provide profile details</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={""}>
                <div className={"account-details-redirect"}>
                  <Link to={ROUTES.UPDATE_ACCOUNT_DETAILS}>
                    Add Your Details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <div className="account-details col-12 p-0 d-flex flex-column">
        <div className="settings-card">
          <div className="card-section head d-flex flex-row justify-content-between">
            <h3>Account Details</h3>
            {this.props.context.auth === FIREBASE_AUTH && (
              <Link to={ROUTES.UPDATE_PASSWORD} className="action">
                Edit
              </Link>
            )}
          </div>
          <div className="card-section last d-flex flex-column ">
            <div className={"account-detail-item"}>
              <div className="">
                <div className="">
                  <FiMail size={25} />
                </div>
                <p className="mb-0">Email</p>
                <div className=""></div>
                <div className="">
                  <p className="info">{this.props.info?.email}</p>
                </div>
              </div>
              <div className="">
                <div className="">
                  <FiKey size={25} />
                </div>
                <p className="mb-0">Password</p>
                <div className=""></div>
                <div className="">
                  <p className="info">********</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {this.props.info &&
          this.props.info.full_name &&
          this.props.info.business_name &&
          this.props.info.business_website &&
          this.props.info.phone_number && (
            <div className="settings-card">
              <div className="card-section head d-flex flex-row justify-content-between">
                <h3>Contact Information</h3>
                <a
                  onClick={() => {
                    this.props.setApplicationContext(
                      { returnURL: pathname, actionType: "edit" },
                      () => {
                        history.push(ROUTES.UPDATE_ACCOUNT_DETAILS);
                      },
                    );
                  }}
                  className="action"
                >
                  Edit
                </a>
              </div>
              <div className="card-section last d-flex flex-column ">
                <div className={"account-detail-item"}>
                  <div className="">
                    <div className="">
                      <FiUser size={25} />
                    </div>
                    <p className="mb-0">User Name</p>
                    <div className=""></div>
                    <div className="">
                      <p className="info">{this.props.info.full_name}</p>
                    </div>
                  </div>
                  <div className="">
                    <div className="">
                      <StoreIcon size={25} />
                    </div>
                    <p className="mb-0">Business Name</p>
                    <div className=""></div>
                    <p className="info">{this.props.info.business_name}</p>
                  </div>
                </div>
                <div className={"account-detail-item"}>
                  <div className="">
                    <div className="">
                      <WebsiteIcon size={25} />
                    </div>
                    <p className="mb-0">Business Website</p>
                    <div className=""></div>
                    <p className="info">{this.props.info.business_website}</p>
                  </div>
                  <div className="">
                    <div className="">
                      <FiPhone size={25} />
                    </div>
                    <p className="mb-0">Phone Number</p>
                    <div className=""></div>
                    <div className="">
                      <p className="info">{this.props.info.phone_number}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        <div className={"settings-card"}>
          <div className="card-section head d-flex flex-row justify-content-between">
            <h3>Business Details</h3>
            <a
              onClick={() => {
                this.props.setApplicationContext(
                  { returnURL: pathname, actionType: "edit" },
                  () => {
                    history.push(ROUTES.BANK_ACCOUNT_DETAILS);
                  },
                );
              }}
              className="action"
            >
              Edit
            </a>
          </div>

          {
            <div
              className={
                "business-details card-section last d-flex flex-column "
              }
            >
              {this.props.missingDetails() && (
                <div
                  className={
                    "warning d-flex flex-row justify-content-between align-items-center"
                  }
                >
                  <div className={"d-flex flex-row justify-content-start"}>
                    <FiInfo />
                    {this.props.businessDetails?.details?.is_verified ? (
                      <div>
                        <h4>Your business is incomplete!</h4>
                        <p className={"info"}>
                          There is missing information in your business details.
                        </p>
                      </div>
                    ) : (
                      <div>
                        <h4>Your business is not verified yet!</h4>
                        <p className={"info"}>
                          Complete your business details and our team will
                          verify your details to send international packages{" "}
                        </p>
                      </div>
                    )}
                  </div>
                  <button
                    onClick={() => {
                      this.props.setApplicationContext(
                        { returnURL: pathname, actionType: "edit" },
                        () => {
                          history.push(ROUTES.BANK_ACCOUNT_DETAILS);
                        },
                      );
                    }}
                  >
                    Verify Business
                  </button>
                </div>
              )}

              {this.props.businessDetails.details &&
                Object.keys(this.props.businessDetails.details).length > 0 &&
                this.props.businessDetails.details.country &&
                this.props.businessDetails.details.country.toString().trim() !==
                  "" &&
                this.props.businessDetails.details.bank_name &&
                this.props.businessDetails.details.bank_name
                  .toString()
                  .trim() !== "" &&
                this.props.businessDetails.details.account_number &&
                this.props.businessDetails.details.account_number
                  .toString()
                  .trim() !== "" &&
                this.props.businessDetails.details.account_name &&
                this.props.businessDetails.details.account_name
                  .toString()
                  .trim() !== "" && (
                  <>
                    <div className={"business-info-section"}>
                      <div className="">
                        <div className="">
                          <BiBriefcase size={25} className={"fill"} />
                        </div>
                        <p className="mb-0">Proof of bank account ownership</p>
                        <div className=""></div>
                        <div className={"bank-info"}>
                          <p className={"info"}>Country</p>
                          <p className="">
                            {
                              COUNTRYLIST[
                                this.props.businessDetails.details.country
                              ].name
                            }
                          </p>
                          <p className={"info"}>Bank Name</p>
                          <p className="">
                            {this.props.businessDetails.details.bank_name}
                          </p>
                          <p className={"info"}>Account number</p>
                          <p className="">
                            {this.props.businessDetails.details.account_number}
                          </p>
                          <p className={"info"}>Account Name</p>
                          <p className="">
                            {this.props.businessDetails.details.account_name}
                          </p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              {this.props.businessDetails.details &&
                Object.keys(this.props.businessDetails.details).length > 0 &&
                this.props.businessDetails.details.document_name &&
                this.props.businessDetails.details.document_type && (
                  <>
                    <div className={"divider"}></div>
                    {this.props.businessDetails.downloadError && (
                      <div className={"error-block"}>
                        <p>{this.props.businessDetails.downloadError}</p>
                      </div>
                    )}
                    <div className={"business-info-section"}>
                      <div className="col-12 p-0">
                        <div className="">
                          <FiFile size={25} />
                        </div>
                        <p className="mb-0">Document Registration Type</p>
                        <div className=""></div>
                        <div
                          className="business-document d-flex flex-row justify-content-between align-items-end"
                          onClick={() =>
                            this.props.downloadBusinessDocument(
                              this.props.businessDetails.details.document_name,
                            )
                          }
                        >
                          <div>
                            <p className={"info"}>
                              {this.props.businessDetails.details.document_type}
                            </p>
                            <p>
                              {this.props.businessDetails.details.document_name}
                            </p>
                          </div>
                          {this.props.businessDetails.loading ? (
                            <Spinner animation={"border"} />
                          ) : (
                            <FiDownload className={"download-icon"} />
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                )}

              {this.props.businessDetails.details &&
                Object.keys(this.props.businessDetails.details).length > 0 &&
                this.props.businessDetails.details?.tax_number && (
                  <>
                    <div className={"divider"}></div>
                    <div className={"business-info-section"}>
                      <div className="">
                        <div className="">
                          <FiHash size={25} />
                        </div>
                        <p className="mb-0">Tax Identification Number</p>
                        <div className=""></div>
                        <p className="info">
                          {this.props.businessDetails.details.tax_number}
                        </p>
                      </div>
                    </div>
                  </>
                )}
            </div>
          }
        </div>
        <div className="settings-card">
          <div className="card-section head d-flex flex-row justify-content-between">
            <div>
              <h3>Address Information</h3>
              {/*<p className="info">Use on customer order confirmation and shopify bills</p>*/}
            </div>
            <Link to={ROUTES.ADDRESS_LIST} className="action">
              Edit
            </Link>
          </div>
          {this.props.defaultAddress ? (
            <div className="card-section last d-flex flex-row justify-content-between">
              <div className="d-flex flex-row">
                <div>
                  <PinIcon size={25} />
                </div>
                <div>
                  <p className="info">
                    {`${this.props.defaultAddress.street1}${this.props.defaultAddress.street2 ? ` #${this.props.defaultAddress.street2}` : ""}, ${this.props.defaultAddress.city},`}
                    <br />
                    {`${this.props.defaultAddress.state}, ${this.props.defaultAddress.zip_code},`}
                    <br />
                    {`${this.props.defaultAddress.country}`}
                  </p>
                </div>
              </div>
              <div>
                <button className="secondary" disabled>
                  Default Address
                </button>
              </div>
            </div>
          ) : (
            <div className="card-section last d-flex flex-row justify-content-between align-items-center">
              <p>Select a default address</p>
              <div className={"address-redirect"}>
                <Link to={ROUTES.ADDRESS_LIST}>Go To Addresses</Link>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({
  userDetails,
  addressList,
  businessDetails,
  applicationContext,
}) => ({
  info: userDetails.info,
  loading: userDetails.loading,
  errors: userDetails.errors,
  defaultAddress: addressList.defaultAddress,
  businessDetails,
  context: applicationContext.context,
});

export default connect(mapStateToProps, {
  getAccountInfo,
  getBusinessDetails,
  downloadBusinessDocument,
  setApplicationContext,
})(AccountDetails);
