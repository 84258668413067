import React, { Component, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { Link, withRouter } from "react-router-dom";
import AddressListItem from "./AddressListItem";
import * as ROUTES from "../../../constants/routes";
import { connect } from "react-redux";
import {
  getAddresses,
  clearCreateAddress,
  deleteAddress,
  searchAddresses,
  getAddressesByPage,
  setAddressPageSize,
} from "../../actions";
import { PHONEBOOK } from "../../../constants/types";
import { ReactComponent as RemoveIcon } from "../../../assets/remove-address-icon.svg";
import Pagination from "../common/Pagination";
import MessageBlock from "../common/MessageBlock";

const AddressList = (props) => {
  const [showConfirmation, setShowConfirmation] = useState(false);

  useEffect(() => {
    props.searchAddresses("");
    props.getAddresses(props.pageSize);
  }, []);

  useEffect(() => {
    props.getAddresses(props.pageSize, props.searchText);
  }, [props.searchText, props.pageSize]);

  const createRedirect = () => {
    props.clearCreateAddress();
    props.toggleForm();
  };
  const renderList = (items) => {
    const list = items.map((item) => {
      return (
        <AddressListItem
          item={item}
          key={item.id}
          className=""
          type={props.type || PHONEBOOK}
          onFinish={() => props.toggleForm()}
          toggleOverlay={(state) => setShowConfirmation(state)}
        />
      );
    });

    return list;
  };

  const renderConfirmation = () => {
    if (showConfirmation && !props.phonebookAddress) {
      setShowConfirmation(false);
      return;
    }
    if (showConfirmation) {
      return (
        <div className="modal">
          <div
            className="backdrop"
            onClick={() => setShowConfirmation(false)}
          ></div>
          <div className="modal-content confirmation">
            <RemoveIcon />
            <div>
              <p>
                You are about to delete address of{" "}
                <b>{`${props.phonebookAddress.name}`}</b>
              </p>
              <p>Are you sure?</p>
            </div>
            <div className="col-12 p-0 d-flex flex-column flex-lg-row justify-content-between">
              <button onClick={() => setShowConfirmation(false)}>Cancel</button>
              <button
                onClick={() =>
                  props.deleteAddress(props.phonebookAddress.id, () => {
                    setShowConfirmation(false);
                    props.getAddresses(props.pageSize, props.searchText);
                  })
                }
                className="delete-button"
              >
                {props.loading ? (
                  <div className="spinner-border m-auto" role="status"></div>
                ) : (
                  "Delete Address"
                )}
              </button>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="address-content settings-card col-12">
      {!!props.errors && (
        <div className={"card-section last"}>
          <MessageBlock type={"error"} message={props.errors} />
        </div>
      )}
      <div className="content-head card-section head col-12 d-flex flex-row justify-content-between align-items-center flex-wrap">
        <div className="d-flex flex-row align-items-center ">
          <h3>Saved Addresses</h3>
          <p
            onClick={() => props.getAddresses(props.pageSize, props.searchText)}
            className="refresh-btn mb-0 mx-3"
          >
            Refresh
          </p>
        </div>
        <a
          onClick={() => createRedirect()}
          className="action d-flex flex-row align-items-center"
        >
          Add New Address
        </a>
      </div>
      <div>
        <div className="address-list list-group">
          {props.loading ? (
            <div className={"card-section last"}>
              <div className="spinner-border text-dark m-auto" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : (
            renderList(props.addresses)
          )}
        </div>
      </div>
      {!props.loading && (
        <div className={"card-section last"}>
          <Pagination
            current={props.current}
            count={props.count}
            pageSize={props.pageSize}
            getListByPage={(pageNo) =>
              props.getAddressesByPage(
                null,
                pageNo,
                props.pageSize,
                props.searchText,
              )
            }
            setPageSize={(size) => props.setAddressPageSize(size)}
          />
        </div>
      )}
      {renderConfirmation()}
    </div>
  );
};

const mapStateToProps = ({ addressList, phonebookAddress }) => ({
  phonebookAddress,
  loading: addressList.loading,
  errors: addressList.errors,
  addresses: addressList.addresses,
  searchText: addressList.searchText,
  current: addressList.currentPage,
  count: addressList.addressResponse?.count,
  pageSize: addressList.pageSize,
});

export default withRouter(
  connect(mapStateToProps, {
    getAddresses,
    clearCreateAddress,
    deleteAddress,
    searchAddresses,
    getAddressesByPage,
    setAddressPageSize,
  })(AddressList),
);
