import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { connect } from "react-redux";
import {
  clearCreateAddress,
  createAddress,
  phonebookAddressUpdate,
  senderAddressUpdate,
  receiverAddressUpdate,
  clearSenderAddress,
  clearReceiverAddress,
  checkoutAddressUpdate,
} from "../../actions";
import {
  CHECKOUT,
  PHONEBOOK,
  RECEIVER,
  SENDER,
} from "../../../constants/types";
import COUNTRY_LIST from "../../../assets/country-list.json";
import CITY_LIST from "../../../assets/cities.json";
import STATE_LIST from "../../../assets/states.json";
import { FiCheck, FiX } from "react-icons/fi";
import PhoneInput from "react-phone-input-2";
import getCountryFlag from "country-flag-icons/unicode";
import { parseError } from "../../actions/ActionHelpers";
import MessageBlock from "../common/MessageBlock";

const requiredFields = [
  "firstName",
  "lastName",
  "state",
  "phone",
  "country",
  "street1",
  "city",
];
const fieldMessage = {
  firstName: "Please enter a valid first name",
  lastName: "Please enter a valid last name",
  phone: "Please enter a valid phone number",
  email: "Please enter a valid email",
  state: "State field is required",
  country: "Country field is required",
  street1: "Street field is required",
  zip_code: "Zip code field is required",
  city: "City field is required",
};

const AddressForm = (props) => {
  const type = props.type || PHONEBOOK;
  const savedAddress = { ...props[`${type}Address`] };

  const [errors, setErrors] = useState({
    valid: true,
  });
  const [addressData, setAddressData] = useState(
    !!savedAddress?.id
      ? {
          ...savedAddress,
          firstName: savedAddress.name.substring(
            0,
            savedAddress.name.indexOf(" "),
          ),
          lastName: savedAddress.name.substring(
            savedAddress.name.indexOf(" ") + 1,
            savedAddress.name.length,
          ),
        }
      : {
          firstName: "",
          lastName: "",
          company: "",
          phone: "",
          email: "",
          street1: "",
          street2: "",
          city: !!CITY_LIST[props.country || "US"]?.length
            ? CITY_LIST[props.country || "US"][0]
            : "",
          state:
            STATE_LIST[props.country || "US"] &&
            !!Object.keys(STATE_LIST[props.country || "US"])?.length
              ? Object.keys(STATE_LIST[props.country || "US"])[0]
              : "",
          zip_code: "",
          country: props.country || "US",
        },
  );
  const [phoneCode, setPhoneCode] = useState(null);
  const [country, setCountry] = useState(
    !!savedAddress?.id
      ? COUNTRY_LIST[savedAddress.country].name
      : COUNTRY_LIST[props.country].name,
  );
  const [defaultAddress, setDefaultAddress] = useState(false);

  const editField = (field, value) => {
    setErrors({});
    const data = { ...addressData };
    data[field] = value;
    if (field === "country" && addressData.country !== value) {
      if (Object.keys(CITY_LIST).includes(addressData.country)) {
        data.city =
          !!savedAddress?.id &&
          !Object.keys(CITY_LIST).includes(savedAddress.country)
            ? savedAddress.city
            : "";
      }
      if (Object.keys(CITY_LIST).includes(value)) {
        data.city = CITY_LIST[value][0];
      }
      if (Object.keys(STATE_LIST).includes(addressData.country)) {
        data.state =
          !!savedAddress?.id &&
          !Object.keys(STATE_LIST).includes(savedAddress.country)
            ? savedAddress.state
            : "";
      }
      if (Object.keys(STATE_LIST).includes(value)) {
        data.state = Object.keys(STATE_LIST[value])[0];
      }
    }
    setAddressData(data);
  };

  useEffect(() => {
    return () => {
      props.clearCreateAddress();
    };
  }, []);

  useEffect(() => {
    if (props.country) {
      if (!addressData.country) {
        editField("country", props.country);
      }
    }
  }, [addressData]);

  useEffect(() => {
    if (!!props.errors) {
      const errorsData = {};
      Object.keys(props.errors).forEach((field) => {
        if (field !== "name") errorsData[field] = props.errors[field];
        else {
          errorsData.firstName = props.errors[field];
          errorsData.lastName = props.errors[field];
        }
      });
      setErrors(errorsData);
    }
  }, [props.errors]);

  const defaultAddressComponent = (type) => {
    if (type == SENDER) {
      return (
        <div className="col-12">
          <div className="">
            <label
              className="form-check-label d-flex flex-row"
              for="flexCheckDefault"
            >
              <div
                className={`default-address-checkbox ${defaultAddress ? "active" : ""} d-flex justify-content-center align-items-center `}
                onClick={(e) => {
                  setDefaultAddress(!defaultAddress);
                }}
              >
                <FiCheck />
              </div>
              Save as Default Sender Address
            </label>
          </div>
        </div>
      );
    }
  };

  const checkFields = () => {
    const address = { ...addressData };
    let checkFields = true;
    const errorsData = {};
    requiredFields.forEach((val) => {
      if (!address[val] || address[val] === "") {
        errorsData[val] = fieldMessage[val];
        checkFields = false;
      }
    });
    if (
      address.phone &&
      !address.phone.match(/^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g)
    ) {
      errorsData.phone = fieldMessage.phone;
      checkFields = false;
    }

    if (!!address.zip_code && address.zip_code.length > 12) {
      errorsData.zip_code =
        "Maximum length for postal/zip code is 12 characters";
      checkFields = false;
    }

    if (
      !!CITY_LIST[addressData.country]?.length &&
      !CITY_LIST[addressData.country].includes(addressData.city)
    ) {
      errorsData.city =
        "The city selected is not in the list of supported cities";
      checkFields = false;
    }

    if (
      STATE_LIST[addressData.country] &&
      !!Object.keys(STATE_LIST[addressData.country])?.length &&
      !Object.keys(STATE_LIST[addressData.country]).includes(addressData.state)
    ) {
      errorsData.state =
        "The state selected is not in the list of supported states";
      checkFields = false;
    }

    setErrors(errorsData);
    return checkFields;
  };

  const checkClose = () => {
    props.toggleForm();
  };

  const create = (type) => {
    const address = { ...addressData };
    if (!address?.email?.toString().trim() && !!props.userInfo?.email) {
      address.email = props.userInfo?.email;
    }

    let valid = checkFields();
    if (!address.zip_code) {
      if (address.country === "KE") {
        address.zip_code = "00254";
      } else {
        setErrors({
          ...errors,
          zip_code: fieldMessage.zip_code,
        });
        valid = false;
      }
    }

    if (valid) {
      address.name = address.firstName + " " + address.lastName;
      delete address.firstName;
      delete address.lastName;
      props.createAddress(address, type, defaultAddress, props.onSuccess);
    }
  };

  const getAddressType = (type) => {
    switch (type) {
      case SENDER:
        return "Sender Address";
      case RECEIVER:
        return "Recipient Address";
      case CHECKOUT:
        return "Store Address";
      default:
        return "New Address";
    }
  };

  const renderCountryList = (country) => {
    const list = Object.keys(COUNTRY_LIST).filter((item) => {
      return (
        item.toLowerCase().includes(country?.toString().toLowerCase()) ||
        COUNTRY_LIST[item].name
          ?.toString()
          .toLowerCase()
          .includes(country?.toString().toLowerCase())
      );
    });
    if (list.length > 0)
      return list.map((item, idx) => {
        return (
          <a
            key={item}
            onClick={() => {
              setCountry(COUNTRY_LIST[item].name);
              // editField('country',item)
            }}
            onKeyDown={(e) => {
              setCountry(COUNTRY_LIST[item].name);
              // editField('country',item)
            }}
          >{`${getCountryFlag(item)} ${COUNTRY_LIST[item]?.name}`}</a>
        );
      });
    return <p></p>;
  };

  const renderCityList = () => {
    if (
      Object.keys(CITY_LIST).includes(addressData.country) &&
      CITY_LIST[addressData.country]?.length > 0
    ) {
      return (
        <select
          value={addressData.city}
          onChange={(e) => editField("city", e.target.value)}
        >
          {!CITY_LIST[addressData.country].includes(addressData.city) && (
            <option>{`${addressData.city} - invalid`}</option>
          )}
          {CITY_LIST[addressData.country]?.map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            );
          })}
        </select>
      );
    }
    return (
      <input
        className=""
        type="text"
        value={addressData.city}
        onChange={(text) => editField("city", text.target.value)}
      />
    );
  };

  const renderStateList = () => {
    if (
      Object.keys(STATE_LIST).includes(addressData.country) &&
      Object.keys(STATE_LIST[addressData.country])?.length > 0
    ) {
      return (
        <select
          value={addressData.state}
          onChange={(e) => editField("state", e.target.value)}
        >
          {!Object.keys(STATE_LIST[addressData.country]).includes(
            addressData.state,
          ) && <option>{`${addressData.state} - invalid`}</option>}
          {Object.keys(STATE_LIST[addressData.country])?.map((item) => {
            return (
              <option key={item} value={item}>
                {item}
              </option>
            );
          })}
        </select>
      );
    }
    return (
      <input
        className=""
        type="text"
        value={addressData.state}
        onChange={(text) => editField("state", text.target.value)}
      />
    );
  };

  const modal = props.show && (
    <div className="modal d-flex justify-content-center justify-content-lg-start aligin-items-center">
      <div onClick={checkClose} className="backdrop"></div>
      <Card className="modal-content settings-card address-form d-block col-11 col-lg-7 m-auto">
        <div className="card-section head col-12 d-flex flex-row justify-content-between">
          <h5 className={"mb-0"}>{getAddressType(type)}</h5>
          <a className="d-flex" onClick={checkClose}>
            <FiX size={25} />
          </a>
        </div>
        <div className="card-section last">
          <div className="col-12">
            <div
              className={
                "row-fields d-flex flex-column flex-lg-row justify-content-between"
              }
            >
              <label
                className={`d-flex flex-column ${errors.firstName ? "error" : ""}`}
              >
                <p className={"d-flex flex-row align-items-center"}>
                  First Name <span className={"required"}>*</span>
                </p>
                <input
                  className=""
                  type="text"
                  value={addressData.firstName}
                  onChange={(text) => {
                    editField("firstName", text.target.value);
                  }}
                />
                <p className={"error"}>First Name is required</p>
              </label>
              <label
                className={`d-flex flex-column ${errors.lastName ? "error" : ""}`}
              >
                <p className={"d-flex flex-row align-items-center"}>
                  Last Name <span className={"required"}>*</span>
                </p>
                <input
                  className=""
                  type="text"
                  value={addressData.lastName}
                  onChange={(text) => {
                    editField("lastName", text.target.value);
                  }}
                />
                <p className={"error"}>Last Name is required</p>
              </label>
            </div>
            <label className="d-flex flex-column">
              <p className={"d-flex flex-row align-items-center"}>Company</p>
              <input
                className=""
                type="text"
                value={addressData.company}
                onChange={(text) => editField("company", text.target.value)}
              />
            </label>
            <label
              className={`d-flex flex-column ${errors.email ? "error" : ""}`}
            >
              {" "}
              <p className={"d-flex flex-row align-items-center"}>Email</p>
              <input
                className=""
                type="email"
                value={addressData.email}
                onChange={(text) => editField("email", text.target.value)}
              />
              <p className={"error"}>{errors.email}</p>
            </label>

            <label
              className={`d-flex flex-column ${errors.phone ? "error" : ""}`}
            >
              <p className={"d-flex flex-row align-items-center"}>
                Phone<span className={"required"}>*</span>
              </p>
              <PhoneInput
                country={
                  phoneCode?.countryCode?.toString().toLowerCase() ||
                  props.country?.toString().toLowerCase()
                }
                className={""}
                value={addressData.phone}
                countryCodeEditable={false}
                onChange={(phone, country, event) => {
                  if (event.target.className.trim() === "form-control")
                    editField("phone", phone);
                  else {
                    editField("phone", country.countryCode);
                    setPhoneCode({
                      countryCode: country.countryCode.toString().toUpperCase(),
                    });
                  }
                }}
                placeholder={"Enter your phone number"}
                enableTerritories
              />
              <p className={"error"}>{errors.phone}</p>
            </label>
          </div>
          <div className="col-12">
            <h5>{`Ship ${type === RECEIVER ? "To" : "From"}`}</h5>
          </div>
          <div className="col-12">
            <div className="col-12 p-0 d-flex flex-column">
              <label
                className={`country-select d-flex flex-column ${errors.country ? "error" : ""}`}
              >
                <p className={"d-flex flex-row align-items-center"}>
                  Country <span className={"required"}>*</span>
                </p>
                <input
                  type={"text"}
                  value={country}
                  onChange={(e) => {
                    setErrors({});
                    if (e.target.value.trim() === "") editField("country", "");
                    setCountry(e.target.value);
                  }}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  onBlur={(e) => {
                    setTimeout(() => {
                      let list = Object.keys(COUNTRY_LIST).filter((item) => {
                        return item
                          .toLowerCase()
                          .includes(e.target.value?.toString().toLowerCase());
                      });
                      if (list.length > 0 && !!e.target.value) {
                        setCountry(COUNTRY_LIST[list[0]]?.name);
                        editField("country", list[0]);
                        return;
                      }
                      list = Object.keys(COUNTRY_LIST).filter((item) => {
                        return COUNTRY_LIST[item]?.name
                          ?.toString()
                          .toLowerCase()
                          .includes(e.target.value?.toString().toLowerCase());
                      });
                      if (list.length > 0 && !!e.target.value) {
                        setCountry(COUNTRY_LIST[list[0]]?.name);
                        editField("country", list[0]);
                      } else if (!addressData.country?.toString().trim()) {
                        setCountry(COUNTRY_LIST[props.country]?.name);
                      } else {
                        setCountry(COUNTRY_LIST[addressData.country]?.name);
                      }
                    }, 200);
                  }}
                  onKeyDown={(e) => {
                    e.stopPropagation();
                  }}
                />
                {country && (
                  <div className={"search-results"}>
                    {renderCountryList(country)}
                  </div>
                )}
                {<p className={"error"}>{errors.country}</p>}
              </label>
            </div>
            <div className="col-12 p-0 d-flex flex-column">
              <label
                className={`d-flex flex-column ${errors.street1 ? "error" : ""}`}
              >
                <p className={"d-flex flex-row align-items-center"}>
                  Street <span className={"required"}>*</span>
                </p>
                <input
                  className=""
                  type="text"
                  value={addressData.street1}
                  onChange={(text) => editField("street1", text.target.value)}
                />
                <p className={"error"}>{errors.street1}</p>
              </label>
            </div>
            <div className="row-fields d-flex flex-column flex-lg-row justify-content-between">
              <label className="d-flex flex-column">
                <p className={"d-flex flex-row align-items-center"}>
                  Apt (street 2)
                </p>
                <input
                  className=""
                  type="text"
                  value={addressData.street2}
                  onChange={(text) => editField("street2", text.target.value)}
                />
              </label>
              <label
                className={`d-flex flex-column ${errors.city ? "error" : ""}`}
              >
                <p className={"d-flex flex-row align-items-center"}>
                  City <span className={"required"}>*</span>
                </p>
                {renderCityList()}
                <p className={"error"}>{errors.city}</p>
              </label>
            </div>
            <div className="row-fields d-flex flex-column flex-lg-row justify-content-between">
              <label
                className={`d-flex flex-column ${errors.state ? "error" : ""}`}
              >
                <p className={"d-flex flex-row align-items-center"}>
                  State <span className={"required"}>*</span>
                </p>
                {renderStateList()}
                <p className={"error"}>{errors.state}</p>
              </label>
              <label
                className={`d-flex flex-column ${errors.zip_code ? "error" : ""}`}
              >
                <p className={"d-flex flex-row align-items-center"}>
                  Postal code / Zip code
                  {addressData.country !== "KE" && (
                    <span className={"required"}>*</span>
                  )}
                </p>
                <input
                  className=""
                  type="text"
                  value={addressData.zip_code}
                  onChange={(text) => editField("zip_code", text.target.value)}
                />
                <p className={"error"}>{errors.zip_code}</p>
              </label>
            </div>
          </div>
          <div>
            {defaultAddressComponent(type)}
            <div className="col-12 d-flex flex-column save-address">
              {!!props.errors && (
                <MessageBlock
                  type={"error"}
                  message={parseError(props.errors)}
                />
              )}
              {
                <button
                  className={`create-address-button ml-auto mb-4 `}
                  onClick={() => create(type)}
                >
                  {props.loading ? (
                    <div className="spinner-border text-light" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  ) : (
                    "Save Address"
                  )}
                </button>
              }
            </div>
          </div>
        </div>
      </Card>
    </div>
  );
  return modal;
};
const mapStateToProps = ({
  phonebookAddress,
  senderAddress,
  receiverAddress,
  checkoutAddress,
  addressForm,
  applicationContext,
  userDetails,
}) => ({
  phonebookAddress,
  senderAddress,
  receiverAddress,
  checkoutAddress,
  loading: addressForm.loading,
  errors: addressForm.errors,
  warnings: addressForm.warnings,
  addressForm,
  country: applicationContext.context.country,
  userInfo: userDetails.info,
});

export default connect(mapStateToProps, {
  phonebookAddressUpdate,
  senderAddressUpdate,
  receiverAddressUpdate,
  checkoutAddressUpdate,
  createAddress,
  clearCreateAddress,
  clearSenderAddress,
  clearReceiverAddress,
})(AddressForm);
