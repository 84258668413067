import React, { Component, useEffect, useState } from "react";
import {
  Link,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import Label from "./Label";
import "../../scss/home.scss";
import Track from "./Track";
import Gifts from "./Gift";
import Account from "./Account";
import history from "../../History";
import { connect } from "react-redux";
import CreateLabel from "./Label/CreateLabel";
import Dashboard from "./Dashboard";
import AddressList from "./Address/AddressList";
import SideBar from "./SideBar";
import Header from "./Header";
import Orders from "./Orders/Orders";
import { useCookies } from "react-cookie";
import { ReactComponent as TestGear } from "../../assets/test-mode-icon.svg";
import { getAccountInfo, logoutSession, testSessionAuth } from "../actions";
import { ReactComponent as BoxLogo } from "../../assets/box-in-logo.svg";
import { auth } from "../../services/firebase";
import { getBusinessDetails } from "../actions/BusinessActions";
import {
  getUserCountry,
  setApplicationContext,
  setContextReturnURL,
} from "../actions/ApplicationContextActions";
import HelpCenter from "./HelpCenter";
import { SESSION_AUTH } from "../../constants/types";
import { FiAlertCircle } from "react-icons/fi";
import { Toaster } from "react-hot-toast";
import Stores from "./Checkouts/Stores";
import CSVImport from "./Orders/CSV/CSVImport";

const Home = (props) => {
  const [loading, setLoading] = useState(true);
  const { pathname, search } = useLocation();
  const [cookies, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    // * remove to make test mode work
    if (cookies.is_test) {
      removeCookie("is_test", {
        domain:
          document.location.hostname.split(".").slice(-2).join(".") ||
          document.location.hostname,
        path: "/",
      });
    }

    props
      .testSessionAuth()
      .then((res) => {
        props.getAccountInfo(
          () => {
            props.getBusinessDetails(
              () => {},
              () => {
                setLoading(false);
              },
            );
          },
          () => setLoading(false),
        );

        props.getUserCountry();

        // * Checking when to redirect to dashboard, redirect to a return URL or stay
        // ? Maybe remove the redirect to dashboard to the Router, use <Redirect/> for ROUTES.HOME
        if (
          (!props.returnURL ||
            props.returnURL === "" ||
            props.returnURL === ROUTES.HOME) &&
          pathname === ROUTES.HOME
        ) {
          history.replace(ROUTES.HOME + ROUTES.DASHBOARD);
        } else if (
          props.returnURL &&
          props.returnURL !== "" &&
          props.returnURL !== ROUTES.HOME &&
          pathname !== ROUTES.HOME + ROUTES.LABEL + ROUTES.CREATE
        ) {
          history.replace(props.returnURL);
          props.setContextReturnURL(ROUTES.HOME);
        }
      })
      .catch((err) => {
        console.log(err);
        // * If there isn't a user logged in, save the return URL before going to the signin page
        props.setContextReturnURL(pathname + search);
        setLoading(false);
        history.replace(ROUTES.SIGN_IN);
      });
  }, []);

  useEffect(() => {
    if (Object.keys(props.info).length === 0) return;

    // * Since the user details and business details are requested concurrently -
    // * check the user details once we know the user details are loaded by checking if it is updating business details
    if (
      !props.businessDetails.loading &&
      (!props.info ||
        !props.info.full_name ||
        !props.info.phone_number ||
        !props.info.business_name ||
        !props.info.business_website ||
        !props.info.monthly_avg_shipment)
    ) {
      props.setApplicationContext({ returnURL: pathname }, () => {
        history.replace(ROUTES.UPDATE_ACCOUNT_DETAILS);
      });
      return;
    }
  }, [props.info, props.businessDetails.loading]);

  if (loading) {
    return (
      <div className={"loading-screen"}>
        <div className="header d-flex flex-row align-items-center">
          <div>
            <BoxLogo />
          </div>
          <h2 className="mb-0">ShipShap</h2>
        </div>
      </div>
    );
  }

  return (
    <div className={`home-body d-flex align-items-center`}>
      <Toaster />
      <Switch>
        <Route
          path={[
            ROUTES.HOME + ROUTES.DASHBOARD,
            ROUTES.HOME + ROUTES.LABEL,
            ROUTES.HOME + ROUTES.ORDERS,
            ROUTES.CSV_IMPORT_PAGE,
            ROUTES.HOME + ROUTES.ACCOUNT,
            ROUTES.HOME + ROUTES.STORES,
            ROUTES.HELP_CENTER,
          ]}
          component={SideBar}
        />
      </Switch>
      <div className="home-content col-12 col-xl-10 ml-auto">
        <Header />
        {props.auth === SESSION_AUTH && (
          <div className={"impersonation-warning"}>
            <div
              className={
                "d-flex flex-row justify-content-start align-items-center"
              }
            >
              <FiAlertCircle />
              <p>
                {props.info?.is_impersonated
                  ? `You are impersonating the account of ${props.info?.full_name || props.info?.email || props.user?.email || "someone else"}`
                  : `You are signed in through admin dashboard account of ${props.info?.full_name || props.info?.email || props.user?.email}`}
              </p>
            </div>
            <button
              onClick={() => {
                props.logoutSession();
              }}
            >
              {" "}
              Log out{" "}
            </button>
          </div>
        )}
        {cookies.is_test && (
          <div className={`test-mode-banner d-flex`}>
            <div className="d-flex flex-row align-items-center justify-content-start">
              <TestGear className="mr-3" />
              <div>
                <h4 className="mb-0">You're in Testing Mode</h4>
                <p className="d-none d-lg-flex mb-0">
                  Every changes or transaction that you’ve made in this mode
                  will not recorded or process
                </p>
              </div>
            </div>
            <button
              className="d-none d-lg-flex"
              onClick={() => {
                removeCookie("is_test", {
                  domain:
                    document.location.hostname.split(".").slice(-2).join(".") ||
                    document.location.hostname,
                  path: "/",
                });
              }}
            >
              Back to Live Mode
            </button>
            <button
              className="d-flex d-lg-none"
              onClick={() => {
                removeCookie("is_test", {
                  domain:
                    document.location.hostname.split(".").slice(-2).join(".") ||
                    document.location.hostname,
                  path: "/",
                });
              }}
            >
              Live Mode
            </button>
          </div>
        )}
        {/*{	props.businessDetails&&*/}
        {/*	<div>*/}
        {/*		<Link to={ROUTES.BANK_ACCOUNT_DETAILS}>Verify Business</Link>*/}
        {/*	</div>*/}
        {/*}*/}
        <Switch>
          <Route path={ROUTES.HOME + ROUTES.DASHBOARD} component={Dashboard} />
          <Route path={ROUTES.HOME + ROUTES.LABEL} exact component={Label} />
          {/* <Route path={ROUTES.HOME + ROUTES.TRACKING} exact component={Track} /> */}
          {/*<Route path={ROUTES.HOME + ROUTES.GIFTS} component={Gifts} />*/}
          <Route path={ROUTES.HOME + ROUTES.ACCOUNT} component={Account} />
          <Route path={ROUTES.HOME + ROUTES.ORDERS} exact component={Orders} />
          <Route
            path={ROUTES.HOME + ROUTES.LABEL + ROUTES.CREATE}
            component={CreateLabel}
          />
          <Route path={ROUTES.HOME + ROUTES.STORES} component={Stores} />
          <Route path={ROUTES.HELP_CENTER} component={HelpCenter} />
          <Route path={ROUTES.CSV_IMPORT_PAGE} component={CSVImport} />
          <Route
            render={() => {
              return <Redirect to={ROUTES.HOME + ROUTES.DASHBOARD} />;
            }}
          />
        </Switch>
      </div>
    </div>
  );
};

const mapStateToProps = ({
  auth,
  userDetails,
  businessDetails,
  applicationContext,
}) => ({
  user: auth.user,
  info: userDetails.info,
  userDetails,
  businessDetails,
  returnURL: applicationContext.context.returnURL,
  auth: applicationContext.context.auth,
});

export default connect(mapStateToProps, {
  getAccountInfo,
  getBusinessDetails,
  setContextReturnURL,
  setApplicationContext,
  getUserCountry,
  testSessionAuth,
  logoutSession,
})(Home);
